var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commonCardsCover"},[_c('div',{staticClass:"commonCardsCover__box",class:{ wide: _vm.widescreen, poster: _vm.getMovieCoverMode }},[_c('img',{staticClass:"commonCardsCover__box--image",attrs:{"srcset":Object.keys(_vm.cover).length === 0
          ? _vm.emptyImage.small_vertical
          : _vm.cover.small_vertical + ' 200w, ' + Object.keys(_vm.cover).length === 0
          ? _vm.emptyImage.small_vertical
          : _vm.cover.medium_vertical + ' 300w, ' + Object.keys(_vm.cover).length ===
            0
          ? _vm.emptyImage.small_vertical
          : _vm.cover.cdn_url + ' 1000w',"sizes":"(max-width: 200px) 100vw, (max-width: 300px) 50vw, 33.3vw","src":Object.keys(_vm.cover).length === 0
          ? _vm.emptyImage.small_vertical
          : _vm.cover.cdn_url,"alt":Object.keys(_vm.cover).length === 0
          ? _vm.emptyImage.small_vertical
          : _vm.cover.title}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }