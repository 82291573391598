<template>
  <div class="commonCardsCover">
    <div
      class="commonCardsCover__box"
      :class="{ wide: widescreen, poster: getMovieCoverMode }"
    >
      <img
        class="commonCardsCover__box--image"
        :srcset="
          Object.keys(cover).length === 0
            ? emptyImage.small_vertical
            : cover.small_vertical + ' 200w, ' + Object.keys(cover).length === 0
            ? emptyImage.small_vertical
            : cover.medium_vertical + ' 300w, ' + Object.keys(cover).length ===
              0
            ? emptyImage.small_vertical
            : cover.cdn_url + ' 1000w'
        "
        sizes="(max-width: 200px) 100vw, (max-width: 300px) 50vw, 33.3vw"
        :src="
          Object.keys(cover).length === 0
            ? emptyImage.small_vertical
            : cover.cdn_url
        "
        :alt="
          Object.keys(cover).length === 0
            ? emptyImage.small_vertical
            : cover.title
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getMovieCoverMode: "config/getMovieCoverMode",
    }),
  },
  data() {
    return {
      emptyImage: {
        title: "professor",
        small_vertical:
          "https://sellercenter-dev.s3.amazonaws.com/media/97/615/77f3ad9d-701a-4a2b-b8a2-edf60822935d/v-small.webp",
        medium_vertical:
          "https://sellercenter-dev.s3.amazonaws.com/media/97/615/77f3ad9d-701a-4a2b-b8a2-edf60822935d/v-mediun.webp",
        large_vertical:
          "https://sellercenter-dev.s3.amazonaws.com/media/97/615/77f3ad9d-701a-4a2b-b8a2-edf60822935d/v-large.webp",
        cdn_url:
          "https://sellercenter-dev.s3.amazonaws.com/media/97/615/77f3ad9d-701a-4a2b-b8a2-edf60822935d/compressed.webp",
      },
    };
  },
  props: {
    widescreen: {
      type: Boolean,
      default: true,
    },
    cover: {
      type: [Object, Array, Number, String],
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsCover {
  &__box {
    width: 100%;
    position: relative;
    overflow: hidden;
    min-width: 153px;
    min-height: 92px;
    &--image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 25%;
    }
    &.wide {
      &::before {
        content: "";
        display: block;
        padding-top: 60.13%;
      }
    }
    &.poster {
      &::before {
        content: "";
        display: block;
        padding-top: 133.33%;
      }
    }
    &::before {
      content: "";
      display: block;
      padding-top: 75%;
    }
  }
}
</style>
